import React from 'react'
import image from "../../components/pages/images/alfahad4.png"
const Loading = () => {
  return (
    <div className='looading'>
        <div className="center">
            <img src={image} alt='alfahad' />
        </div>
      
    </div>
  )
}

export default Loading
